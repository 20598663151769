import React from 'react';
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout';
import CustomCounter from '../../components/counterup/CustomCounter';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import Layout from '../../common/Layout';
import SEO from '../../common/SEO';
import { Navigate } from 'react-router-dom';

export const AboutCountryDubai = () => {
    const dubaiBenefits = [
        {
            title: 'World-Class Infrastructure',
            icon: 'icon-Building',
            number: 1000,
            suffix: '+'
        },
        {
            title: 'Tax-Free Income',
            icon: 'icon-Money',
            number: 100,
            suffix: '%'
        },
        {
            title: 'Global Business Hub',
            icon: 'icon-Globe',
            number: 500,
            suffix: '+'
        },
        {
            title: 'Diverse Culture & Safety',
            icon: 'icon-Shield',
            number: 300,
            suffix: '+'
        }
    ];

    const handleClick = () => {
        Navigate('/contact-us');
    };

    const dubaiItems = [
        {
            title: 'University of Dubai',
            numberOfCourses: '',
            icon: 'icon-Schoolbag',
            link: '#'
        },
        {
            title: 'American University in Dubai',
            numberOfCourses: '',
            icon: 'icon-student-read',
            link: '#'
        },
        {
            title: 'Middlesex University Dubai',
            numberOfCourses: '',
            icon: 'icon-Pencil',
            link: '#'
        },
        {
            title: 'Heriot-Watt University Dubai',
            numberOfCourses: '',
            icon: 'icon-Class',
            link: '#'
        },
        {
            title: 'University of Wollongong in Dubai',
            numberOfCourses: '',
            icon: 'icon-Setting',
            link: '#'
        },
        {
            title: 'Canadian University Dubai',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'British University in Dubai',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        }
    ];

    const dubaiData = {
        title: 'Dubai',
        mTitle: 'Why Study in Dubai?',
        desc_1: "Dubai is known for its cutting-edge infrastructure, prestigious universities, and vibrant multicultural environment. The city offers high-quality education through internationally recognized institutions, making it an attractive destination for students worldwide. With a strong focus on innovation and business, Dubai provides unique opportunities for students to gain practical experience and establish global connections.",
        desc_2: 'Apart from academics, Dubai offers an excellent quality of life, tax-free income opportunities, and a safe environment. Its dynamic business hub, strategic location, and exposure to international markets make it an ideal place to study and build a successful career.',
        subTitle: 'Features of Dubai',
        funFacts: dubaiBenefits,
        items: dubaiItems
    };

    return (
        <>
            <SEO title={`About ${dubaiData.title}`} />
            <Layout>
                <BreadcrumbOne
                    title="Study Abroad"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Study in Dubai"
                />
                <CustomHomeAbout
                    sectionFormat={'1'}
                    title={dubaiData.title}
                    mTitle={dubaiData.mTitle}
                    desc_1={dubaiData.desc_1}
                    desc_2={dubaiData.desc_2}
                    subTitle={dubaiData.subTitle}
                    imgSrc={'/images/about/about-07/dubai.png'}
                />
                <CustomCounter
                    funFacts={dubaiData.funFacts}
                />
                <div className="container eduvibe-animated-shape">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                classes="text-center"
                                slogan=""
                                title="Universities in Dubai"
                            />
                        </div>
                    </div>
                    <CategoryThreeCustom
                        items={dubaiData.items}
                    />
                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                    <button
                        className="rn-btn edu-btn mb--30"
                        type="button"
                        onClick={() => handleClick()}>
                        <span>Get in touch with us</span>
                    </button>
                </div>
            </Layout>
        </>
    );
};