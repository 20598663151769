import React from 'react';
import { Link } from 'react-router-dom';

// Button style objects
const btnLoginStyles = {
  backgroundColor: '#007bff', // Primary blue color
  color: 'white',             // Text color
  padding: '12px 24px',       // Padding for the button
  fontSize: '14px',           // Font size
  fontWeight: 600,            // Bold text
  border: 'none',             // Remove border
  borderRadius: '30px',       // Rounded corners
  cursor: 'pointer',          // Pointer cursor on hover
  transition: 'transform 0.3s ease', // Transition for zoom effect
  display: 'inline-flex',     // Inline-flex for horizontal alignment
  alignItems: 'center',       // Vertical alignment
  textTransform: 'uppercase', // Uppercase text
  textDecoration: 'none',     // Remove underline
  boxShadow: '0 4px 8px rgba(0, 123, 255, 0.2)', // Subtle shadow for depth
};

const btnLoginFocusStyles = {
  outline: 'none',  // Remove default outline
  boxShadow: 'none' // Remove shadow focus
};

const Nav = () => {
  return (
    <ul className="mainmenu">
      <li><Link to="/">Home</Link></li>
     {/**  <li><Link to="#">B2B</Link></li>
      <li><Link to="#">Work Permit</Link></li> **/}
      
      <li className="has-droupdown">
        <Link to="#">Book an Exam</Link>
        <ul className="submenu">
          <li><Link to="/selt">UKVI - SELT</Link></li>
          <li><Link to="/toefl">ETS - TOEFL</Link></li>
          <li><Link to="/ielts">IDP - IELTS</Link></li>
          <li><Link to="/pte-versant-exam">PTE - VERSANT</Link></li>
          {/** <li><Link to="#">PEARSON VERSANT</Link></li> **/}
          <li><Link to="/gre">GRE</Link></li>
          <li><Link to="/gmat">GMAT</Link></li>
          <li><Link to="/sat">SAT</Link></li>
        </ul>
      </li>
      
      <li className="has-droupdown">
        <Link to="#">Training/Mock</Link>
        <ul className="submenu">
          <li className="has-droupdown">
            <Link to="#">Mock</Link>
            <ul className="submenu">
              <li><Link to="/toefl">ETS-TOEFL</Link></li>
              <li><Link to="/selt">SFE-SELT</Link></li>
              <li><Link to="/checkout">PEARSON VERSANT - 499/-</Link></li>
            </ul>
          </li>
        </ul>
      </li>
      
      {/**
      <li className="has-droupdown">
        <Link to="#">SyZyGy</Link>
        <ul className="submenu">
          <li><Link to="/unit1c-course/1#">Demo</Link></li>
          <li><Link to="#">Register</Link></li>
        </ul>
      </li>
      **/}
      
      <li className="has-droupdown">
        <Link to="#">Study Abroad</Link>
        <ul className="submenu">
          <li><Link to="/about-country-usa">USA</Link></li>
          <li><Link to="/about-country-uk">UK</Link></li>
          <li><Link to="/about-country-australia">AUS</Link></li>
          <li><Link to="/about-country-europe">Europe</Link></li>
          {/**
          <li><Link to="#">NZ</Link></li>
          <li><Link to="#">Canada</Link></li>
          <li><Link to="#">India</Link></li>
          **/}
        </ul>
      </li>
      
      <li><Link to="/blog">Blog</Link></li>
      
      {/**
      <li><Link to="/contact-us">Contact Us</Link></li>
      **/}
      
      {/**
      <li className="nav-btn">
        <Link to="https://login.fetc.in/book_appointment">
          <button
            className="btn btn-appointment"
            style={btnLoginStyles}
            onMouseOver={(e) => (e.target.style.transform = 'scale(1.05)')}
            onFocus={(e) => Object.assign(e.target.style, btnLoginFocusStyles)}
            onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
            onBlur={(e) => Object.assign(e.target.style, btnLoginStyles)}
            onMouseDown={(e) => e.preventDefault()}
            onMouseUp={(e) => e.preventDefault()}
          >
            Book an Appointment
          </button>
        </Link>
      </li>
      **/}
      
     </ul>
  );
};

export default Nav;
