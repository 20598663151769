import React, { useState } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import LoginForm from '../../components/form/LoginForm';
import RegisterForm from '../../components/form/RegisterForm';
import { useNavigate } from 'react-router-dom';

const LoginRegister = () => {
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(true); // State to toggle between login and register forms

    const goBack = () => {
        navigate('/');
    }

    const toggleForm = () => {
        setIsLogin(!isLogin);
    }

    return (
        <Layout>
            <SEO title="Login/Register" />
            
            <BreadcrumbOne 
                title="Login/Register" 
                rootUrl="/" 
                parentUrl="Home" 
                currentUrl="Login/Register"
            />
            
            <div className="login-register-page-wrapper bg-color-white">
                <div className="container checkout-page-style">
                    <div className="row g-5">
                        <div className="col-lg-6 mx-auto">
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <img 
                                    src="/images/logo/FETC-logo.png" 
                                    alt="Main Logo" 
                                    style={{filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.3))', width: '200px'}} 
                                />
                            </div>
                            
                            {/* Conditionally render LoginForm or RegisterForm based on the state */}
                            {isLogin ? <LoginForm /> : <RegisterForm />}
                            
                            <div className="d-flex justify-content-center mt-3">
                                <button 
                                    className="rn-btn edu-btn mb--30" 
                                    type="button"
                                    onClick={toggleForm}>
                                    <span>{isLogin ? 'Register' : 'Login'}</span>
                                </button>
                            </div>
                        </div>
                        
                        <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                            <button 
                                className="rn-btn edu-btn mb--30" 
                                type="button"
                                onClick={goBack}>
                                <span>Go Back</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default LoginRegister;
