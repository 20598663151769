import { useState } from 'react';
import { Link } from 'react-router-dom';
import Nav from './Nav';
import HeaderSticky from './HeaderSticky';
import ResponsiveMenu from './ResponsiveMenu';

const HeaderOne = ({ styles, disableSticky }) => {
    const [offcanvasShow, setOffcanvasShow] = useState(false);
    const [searchPopup, setSearchPopup] = useState(false);

    // Toggle offcanvas menu visibility
    const onCanvasHandler = () => {
        setOffcanvasShow((prevState) => !prevState);
    };

    // Toggle search popup visibility
    const onSearchHandler = () => {
        setSearchPopup((prevState) => !prevState);
    };

    // Add or remove search-popup-active class from body
    if (searchPopup) {
        document.body.classList.add('search-popup-active');
    } else {
        document.body.classList.remove('search-popup-active');
    }

    // Check if sticky header is needed
    const sticky = HeaderSticky(118);
    const classes = sticky ? 'sticky' : '';
    const stickyStatus = disableSticky ? '' : ' header-sticky';

    // Login Button CSS Styles (using style object)
    const btnLoginStyles = {
        backgroundColor: '#007bff',  /* Primary blue color */
        color: 'white',               /* Text color */
        padding: '12px 24px',         /* Add padding to the button */
        fontSize: '14px',             /* Font size */
        fontWeight: 600,              /* Bold text */
        border: 'none',               /* Remove border */
        borderRadius: '30px',         /* Rounded corners */
        cursor: 'pointer',           /* Show a pointer cursor on hover */
        transition: 'transform 0.3s ease', /* Apply transition for the zoom effect */
        display: 'inline-flex',       /* Align text horizontally */
        alignItems: 'center',         /* Vertically align the text */
        textTransform: 'uppercase',   /* Uppercase text */
        textDecoration: 'none',       /* Remove underline */
        boxShadow: '0 4px 8px rgba(0, 123, 255, 0.2)', /* Subtle shadow for depth */
    };

    // This is for hover zoom effect
    const btnLoginHoverStyles = {
        transform: 'scale(.95)',     /* Slight zoom-in effect */
    };

    const btnLoginFocusStyles = {
        outline: 'none',              /* Remove default outline */
        boxShadow: 'none',            /* Remove shadow focus */
    };

    return (
        <>
            <header className={`edu-header disable-transparent ${stickyStatus} ${styles || ''} ${classes || ''}`}>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        {/* Logo Section */}
                        <div className="col-lg-2 col-md-3 col-6">
                            <div className="logo">
                                <Link to="/">
                                    <img className="logo-light" src="/images/logo/FETC-logo.png" alt="Main Logo" />
                                </Link>
                            </div>
                        </div>

                        {/* Navigation Section */}
                        <div className="col-lg-8 col-md-6 d-none d-xl-block">
                            <nav className="mainmenu-nav">
                                <Nav />
                            </nav>
                        </div>
{/* 
// Header Right Section (Book an Appointment) 
<div className="col-lg-2 col-md-3 col-6">
    <div className="header-right d-flex justify-content-end">
        <div className="header-quote">
            //Book Appointment Button
            <div className="quote-icon quote-appointment">
                <Link to="/book-an-appointment">
                    <button 
                        className="btn btn-appointment" 
                        style={btnLoginStyles}
                        onMouseOver={(e) => e.target.style.transform = 'scale(1.05)'} // Hover zoom
                        onFocus={(e) => e.target.style = { ...btnLoginStyles, ...btnLoginFocusStyles }}
                        onMouseOut={(e) => e.target.style.transform = 'scale(1)'} // Reset zoom on mouse out
                        onBlur={(e) => e.target.style = { ...btnLoginStyles }} // Reset on blur
                        onMouseDown={(e) => e.preventDefault()} // Prevent any design changes during click
                        onMouseUp={(e) => e.preventDefault()} // Prevent any design changes on release
                    >
                        Book an Appointment
                    </button>
                </Link>
            </div>
        </div>
    </div>
</div>*/}

                        {/* Header Right Section (Login and Hamburger) */}
                        <div className="col-lg-2 col-md-3 col-6">
                            <div className="header-right d-flex justify-content-end">
                                <div className="header-quote">
                                    {/* Login Button */}
                                    <div className="quote-icon quote-user">
                                        <Link to="/login">
                                            <button 
                                                className="btn btn-login" 
                                                style={btnLoginStyles}
                                                onMouseOver={(e) => e.target.style.transform = 'scale(1.05)'} // Hover zoom
                                                onFocus={(e) => e.target.style = { ...btnLoginStyles, ...btnLoginFocusStyles }}
                                                onMouseOut={(e) => e.target.style.transform = 'scale(1)'} // Reset zoom on mouse out
                                                onBlur={(e) => e.target.style = { ...btnLoginStyles }} // Reset on blur
                                                onMouseDown={(e) => e.preventDefault()} // Prevent any design changes during click
                                                onMouseUp={(e) => e.preventDefault()} // Prevent any design changes on release
                                            >
                                                LOGIN
                                            </button>
                                        </Link>
                                    </div>

                                    {/* Hamburger Menu (Visible on smaller screens) */}
                                    <div className="hamberger quote-icon d-block d-xl-none">
                                        <button className="hamberger-button" onClick={onCanvasHandler}>
                                            <i className="ri-menu-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </header>

            {/* Responsive Menu */}
            <ResponsiveMenu
                show={offcanvasShow}
                onClose={onCanvasHandler}
                showSearch={searchPopup}
                onSearch={onSearchHandler}
            />
        </>
    );
};

export default HeaderOne;
