import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';

const AboutSix = () => {
    // Vision Statement Styles
    const visionStatementStyle = {
        marginTop: 'px',
        padding: '20px',
        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Light transparent black background
        borderRadius: '8px',
        textAlign: 'center',
        color: '#489ec4',  // Set the text color
        fontSize: '12px',
        lineHeight: '1.6',
        fontWeight: '400',
    };

    const visionTextStyle = {
        fontSize: '20px',
        lineHeight: '1.8',
        fontWeight: '600',
        margin: '0',
        color: '#000',  // Set the text color
    };

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '20vh', // Full viewport height
    };

    const innerContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    };

    return (
        <>
            <div className="about-six-container" style={containerStyle}>
                <div className="container eduvibe-animated-shape" style={innerContainerStyle}>
                    <div className="workshop-inner">
                    

                        {/* Vision Statement */}
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}
                        >
                            <div className="vision-statement" style={visionStatementStyle}>
                                <p style={visionTextStyle}>
                                    <strong>Embrace all the verticals related to education</strong>
                                    <br />
                                    from Career counselling, Language Enhancement, and curbing a roadmap of students' future
                                    in course selection and placement.
                                </p>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            className="read-more-btn"
                            animateOnce={true}
                        >
                            {/* Add your link or additional content if needed */}
                        </ScrollAnimation>
                    </div>
                </div>

                {/* Shape Dot Wrapper */}
                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-09-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-04-05.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-13-02.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutSix;