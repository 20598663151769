const PostData = [
    {
        title: 'Transforming Ambitions into Global Achievements',
        description: 'Explore how you can transform your ambitions into a global achievement through studying, working, and settling abroad.',
        banner: 'https://img.freepik.com/free-photo/woman-with-spacesuit-helmet-flag-earth_23-2148306559.jpg',
        authorIcon: 'https://img.freepik.com/free-icon/user_318-671241.jpg', // Example author icon
        authorName: 'John Doe',
        postUrl: '/blog/transforming-ambitions-global-achievements',
    },
    {
        title: 'Work, Study & Settle Abroad',
        description: 'Learn everything about working, studying, and settling abroad with expert guidance for a successful international journey.',
        banner: 'https://img.freepik.com/premium-vector/flat-banner-getting-education-abroad-cartoon_81534-2509.jpg',
        authorIcon: 'https://img.freepik.com/free-icon/user_318-671242.jpg', // Example author icon
        authorName: 'Jane Smith',
        postUrl: '/blog/work-study-settle-abroad',
    },
    {
        title: 'English Language Training',
        description: 'Enhance your communication skills through English Language training, preparing for exams like IELTS and TOEFL.',
        banner: 'https://img.freepik.com/free-psd/language-learning-landing-page-design-template_23-2149113650.jpg',
        authorIcon: 'https://img.freepik.com/free-icon/user_318-671243.jpg', // Example author icon
        authorName: 'Alice Brown',
        postUrl: '/blog/english-language-training',
    },
    {
        title: 'Blended & Hybrid Training Structure',
        description: 'Discover the advantages of blended and hybrid training models that provide the best of both in-person and online learning experiences.',
        banner: 'https://img.freepik.com/free-photo/colleagues-clapping-hands-celebrating-successful-presentation-boardroom-team-business-people-receiving-applause-from-coworkers-feeling-satisfied-about-success-accomplishment_482257-29947.jpg',
        authorIcon: 'https://img.freepik.com/free-icon/user_318-671244.jpg', // Example author icon
        authorName: 'David Lee',
        postUrl: '/blog/blended-hybrid-training-structure',
    },
];

export default PostData;
