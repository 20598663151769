import React from 'react';
import { useNavigate } from 'react-router-dom';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const StudyAbroad = () => {
    const navigate = useNavigate();

    const handleCountryClick = (country) => {
        navigate(`/about-country-${country}`);
    };

    const countries = [
        { 
            name: 'USA', 
            url: '#', 
            opportunities: 120, 
            imgUrl: 'https://didmdw8v48h5q.cloudfront.net/wp-content/uploads/2022/01/USA_BlogPost-1024x538.png' 
        },
        { 
            name: 'UK', 
            url: '#', 
            opportunities: 80, 
            imgUrl: 'https://www.studyoverseashelp.com/blog/wp-content/uploads/2021/05/study-in-UK.png' 
        },
        { 
            name: 'Australia', 
            url: '#', 
            opportunities: 150, 
            imgUrl: 'https://www.studyoverseashelp.com/blog/wp-content/uploads/2021/10/Study-in-Australia.png' 
        },
        { 
            name: 'New Zealand', 
            url: '#', 
            opportunities: 40, 
            imgUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/Flag_of_New_Zealand.svg/1200px-Flag_of_New_Zealand.svg.png' 
        },
        { 
            name: 'Europe', 
            url: '#', 
            opportunities: 200, 
            imgUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/Flag_of_Europe.svg/1200px-Flag_of_Europe.svg.png' 
        },
        { 
            name: 'Canada', 
            url: '#', 
            opportunities: 90, 
            imgUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Canada_%28Pantone%29.svg/1200px-Flag_of_Canada_%28Pantone%29.svg.png' // Add image URL for Canada
        },
        { 
            name: 'India', 
            url: '#', 
            opportunities: 180, 
            imgUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png?20240827082344' // Add image URL for India
        }
    ];

    return (
        <>
            <SEO title="Study Abroad" />
            <Layout>
                <BreadcrumbOne
                    title="Study Abroad"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Study Abroad"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                <div className="thumbnail mb--50 text-center">
                                    <img className="radius-small" src="/images/others/studyabroad.png" style={{ height: '500px' }} alt="Study Abroad" />
                                    <h2 className="title">Study Abroad with FETC</h2>
                                </div>

                                <p>Unlock a world of opportunities with FETC, your trusted partner in pursuing education overseas. At FETC, we understand that studying abroad is more than just enrolling in a university—it's about embarking on a life-changing journey that shapes your future. We are dedicated to providing comprehensive and personalized support at every stage of your study abroad experience. From selecting the right course and university to guiding you through the intricacies of visa applications, FETC is here to make your dreams of international education a reality.</p>

                                <h4 className="title">Explore Prime Study Destinations</h4>

                                <p>Choosing the right destination is crucial to your academic and career success. Each country offers unique advantages, and at FETC, we have the expertise to help you find the perfect fit. Explore our top study destinations below, and click on your preferred country to discover why it's the ideal place to further your education.</p>

                                <div className="row g-5 mt--20">
                                    {countries.map((country, index) => (
                                        <div className="col-lg-4 col-md-6" key={index}>
                                            <div
                                                className="contact-address-card-2"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleCountryClick(country.name.toLowerCase())}
                                            >
                                                <div className="inner">
                                                    <div className="icon" style={{ textAlign: 'center', marginBottom: '10px' }}>
                                                        {/* Country Image Above the Name */}
                                                        <img 
                                                            src={country.imgUrl} 
                                                            alt={country.name} 
                                                            style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                                                        />
                                                    </div>
                                                    <div className="content" style={{ textAlign: 'center' }}>
                                                        {/* Country Name Below the Image */}
                                                        <h6 className="title" style={{ fontSize: '24px' }}>
                                                            {country.name}
                                                        </h6>
                                                        <p>{country.opportunities} opportunities available</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default StudyAbroad;
