import React from 'react';
import { Link } from 'react-router-dom';
import ScrollTopButton from './ScrollTopButton';

// Font Awesome for social icons
import { FaFacebook, FaLinkedin, FaInstagram, FaEnvelope } from 'react-icons/fa';
import { FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa'; // For map and phone icons

const Footer = () => {
    return (
        <footer style={footerStyle}>
            <div style={footerWrapperStyle}>
                {/* Social Media Icons Section */}
                <div style={socialSectionStyle}>
                    <h3 style={socialTitleStyle}>Follow Us</h3>
                    <div style={socialIconsStyle}>
                        <a href="https://www.facebook.com/profile.php?id=100090036609090" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
                            <FaFacebook size={30} color="#3b5998" />
                        </a>
                        <a href="https://www.linkedin.com/company/96215379/admin/feed/posts/" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
                            <FaLinkedin size={30} color="#0077b5" />
                        </a>
                        <a href="https://www.instagram.com/fetc_trainingcentre?igsh=NmI0bjM3NnY4ZXhh&utm_source=qr" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
                            <FaInstagram size={30} color="#e4405f" />
                        </a>
                        <a href="mailto:info@fetc.in" style={socialIconStyle}>
                            <FaEnvelope size={30} color="#ea4335" />
                        </a>
                    </div>
                </div>

                {/* Footer Content Section */}
                <div style={footerContentStyle}>
                    {/* Logo Section */}
                    <div style={logoSectionStyle}>
                        <Link to={process.env.PUBLIC_URL + "/"}>
                            <img
                                style={logoImageStyle}
                                src="/images/logo/FETC-logo.png"
                                alt="Footer Logo"
                            />
                        </Link>
                    </div>

                   {/* Explore Section */}
<div style={exploreSectionStyle}>
    <h3 style={exploreTitleStyle}>Explore</h3>
    <ul style={exploreListStyle}>
        <li style={exploreItemStyle}><Link to="/about-us" style={exploreLinkStyle}>About Us</Link></li>
        <li style={exploreItemStyle}><Link to="/faq" style={exploreLinkStyle}>FAQs</Link></li>
        <li style={exploreItemStyle}><Link to="/testimonial" style={exploreLinkStyle}>Testimonials</Link></li>
        <li style={exploreItemStyle}><Link to="https://login.fetc.in/book_appointment" style={exploreLinkStyle}>Book An Appointment</Link></li>
        <li style={exploreItemStyle}><Link to="/contact-us" style={exploreLinkStyle}>Contact Us</Link></li>
        
        {/* These links will now appear in a new line */}
        <div style={exploreSectionStyle}>
            <li style={exploreItemStyle}><Link to="/terms" style={exploreLinkStyle}>Terms</Link></li>
            <li style={exploreItemStyle}><Link to="/privacy" style={exploreLinkStyle}>Privacy</Link></li>
            <li style={exploreItemStyle}><Link to="/refund" style={exploreLinkStyle}>Refund</Link></li>
        </div>
    </ul>
</div>


                    {/* Contact Section */}
                    <ContactSection />
                </div>

                {/* Copyright Section */}
                <div style={copyrightStyle}>
                    <p style={copyrightTextStyle}>
                        Copyright 2024 <a href="/" style={copyrightLinkStyle}>FETC</a> All Rights Reserved
                    </p>
                </div>
            </div>

            {/* Scroll to Top Button */}
            <ScrollTopButton />
        </footer>
    );
};


//ContactSection
const ContactSection = () => {
    return (
        <div style={contactSectionStyle}>
            <h3 style={contactTitleStyle}>Contact Us</h3>

            {/* Head Office */}
            <div style={addressItemStyle}>
                <b>Head Office - Surat Vesu</b>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FaMapMarkerAlt style={{ marginRight: '10px' }} />
                    <a href="https://maps.app.goo.gl/bMtkZiBTZjR8ydAV6" target="_blank" rel="noopener noreferrer">
                        238-239, Second Floor, Roongta Signature, Opp. Shyam Mandir, VIP Road, Vesu, Surat - 395007
                    </a>
                </div>
            </div>

            {/* Branch Office - Surat Varachha */}
            <div style={addressItemStyle}>
                <b>Branch Office - Surat Varachha</b>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FaMapMarkerAlt style={{ marginRight: '10px' }} />
                    <a href="https://maps.app.goo.gl/QTm4EhqhDi1c9X6e7" target="_blank" rel="noopener noreferrer">
                        328, Radhika Optima, Opp. Yamuna Chowk, Mota Varachha, Surat, India - 394101
                    </a>
                </div>
            </div>

            {/* Branch Office - Rajkot */}
            <div style={addressItemStyle}>
                <b>Branch Office - Rajkot</b>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FaMapMarkerAlt style={{ marginRight: '10px' }} />
                    <a href="https://maps.app.goo.gl/MtpYZnAS5ntYnYDi8" target="_blank" rel="noopener noreferrer">
                        210, Dhanranjni Complex, Dr. Yagnik Rd, Next to The Imperial Palace, New Jagnath, Rajkot, India - 360007
                    </a>
                </div>
            </div>

            {/* Contact Number */}
            <div style={addressItemStyle}>
                <FaPhoneAlt style={{ marginRight: '10px' }} />
                <a href="tel:+919033347201" style={phoneStyle}>+91 9033347201</a>
            </div>
        </div>
    );
};


// Inline CSS styles
const footerStyle = {
    padding: '40px 50px',
    background: 'linear-gradient(45deg, #feecde, #fff2e9, #fff8f2, #ebe5f1)',
    color: '#333',
    textAlign: 'left',
    boxSizing: 'border-box',
};

const footerWrapperStyle = {
    maxWidth: 'calc(100% - 100px)',
    margin: '0 auto',
    boxSizing: 'border-box',
};

const socialSectionStyle = {
    textAlign: 'right',
    marginBottom: '20px',
};

const socialTitleStyle = {
    fontSize: '24px',
    marginBottom: '10px',
};

const socialIconsStyle = {
    display: 'flex',
    justifyContent: 'right',
    gap: '15px',
};

const socialIconStyle = {
    textDecoration: 'none',
};

const footerContentStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '40px',
    flexWrap: 'wrap',
};

const logoSectionStyle = {
    flex: '1 1 30%',
    textAlign: 'center',
    marginBottom: '20px',
};

const logoImageStyle = {
    maxWidth: '150px',
};

const exploreSectionStyle = {
    flex: '1 1 30%',
    textAlign: 'left',
    marginBottom: '20px',
};

const exploreTitleStyle = {
    fontSize: '24px',
    marginBottom: '10px',
};

const exploreListStyle = {
    listStyleType: 'none',
    padding: '0',
    margin: '0',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
};

const exploreItemStyle = {
    textDecoration: 'none',
    color: '#000',
    display: 'block',
};

const exploreLinkStyle = {
    color: '#333',
    textDecoration: 'none',
    fontSize: '16px',
};

const contactSectionStyle = {
    flex: '1 1 30%',
    textAlign: 'left',
    marginBottom: '20px',
};

const contactTitleStyle = {
    fontSize: '24px',
    marginBottom: '10px',
};

const addressItemStyle = {
    margin: '10px 0',
    fontSize: '14px',
    textAlign: 'left',
};

const mapLinkStyle = {
    color: '#007BFF',
    textDecoration: 'none',
    fontSize: '14px',
    marginTop: '5px',
    display: 'inline-block',
};

const phoneStyle = {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#007BFF',
    textDecoration: 'none',
};

const copyrightStyle = {
    marginTop: '20px',
    marginBottom: '0px',
    textAlign: 'center',
};

const copyrightTextStyle = {
    fontSize: '14px',
};

const copyrightLinkStyle = {
    color: '#333',
    textDecoration: 'none',
};

export default Footer;
