import React from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import BreadcrumbOne from '../common/breadcrumb/BreadcrumbOne';

const Privacy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <BreadcrumbOne 
        title="Privacy Policy"
        paragraph="Last updated: February 03, 2025"
        breadcrumbPath={[
          { name: "Home", path: "/" },
          { name: "Privacy Policy", path: "/privacy-policy" }
        ]}
      />
      <div className="privacy-policy-container" style={styles.termsContainer}>
        <section className="privacy-policy-body" style={styles.termsBody}>
          <h2 style={styles.termsBodyH2}>Privacy Policy</h2>
          <p style={styles.termsBodyP}><strong>Last updated:</strong> February 03, 2025</p>

          <h3 style={styles.termsBodyH3}>1. Introduction</h3>
          <p style={styles.termsBodyP}>This Privacy Policy explains how we collect, use, and protect your personal information when you access our website and services. By using our service, you agree to the terms described in this policy.</p>

          <h3 style={styles.termsBodyH3}>2. Information Collection</h3>
          <p style={styles.termsBodyP}>We collect personal information that you provide to us, such as your name, email address, and payment details when you register or make a purchase. We also collect usage data such as IP addresses and browser type for improving our service and tracking usage trends.</p>

          <h3 style={styles.termsBodyH3}>3. How We Use Your Information</h3>
          <p style={styles.termsBodyP}>We use the information we collect to:</p>
          <ul style={styles.termsBodyP}>
            <li>Provide and improve our service</li>
            <li>Communicate with you regarding your account or service-related matters</li>
            <li>Personalize your experience and respond to your inquiries</li>
          </ul>

          <h3 style={styles.termsBodyH3}>4. Data Security</h3>
          <p style={styles.termsBodyP}>We implement reasonable security measures to protect your personal data from unauthorized access, alteration, or destruction. However, no method of electronic storage is 100% secure.</p>

          <h3 style={styles.termsBodyH3}>5. Sharing of Data</h3>
          <p style={styles.termsBodyP}>We do not sell or rent your personal information to third parties. However, we may share your information with trusted service providers to help us deliver our services to you.</p>

          <h3 style={styles.termsBodyH3}>6. Your Rights</h3>
          <p style={styles.termsBodyP}>You have the right to access, update, or delete your personal information. If you wish to exercise these rights, please contact us at the details provided below.</p>

          <h3 style={styles.termsBodyH3}>7. Changes to This Privacy Policy</h3>
          <p style={styles.termsBodyP}>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date. Please review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>

          <h3 style={styles.termsBodyH3}>8. Contact Us</h3>
          <p style={styles.termsBodyP}>If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:info@fetc.in" style={styles.termsBodyA}>info@fetc.in</a>.</p>
        </section>
      </div>
    </Layout>
  );
};

const styles = {
  termsContainer: {
    fontFamily: 'Arial, sans-serif',
    margin: '20px auto',
    padding: '40px',
    maxWidth: '1000px',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
  },
  termsBody: {
    lineHeight: '1.6',
  },
  termsBodyH2: {
    fontSize: '24px',
    color: '#34495e',
    marginTop: '20px',
    fontWeight: 'bold',
  },
  termsBodyH3: {
    fontSize: '20px',
    color: '#34495e',
    marginTop: '15px',
  },
  termsBodyP: {
    fontSize: '16px',
    color: '#2c3e50',
    marginBottom: '16px',
  },
  termsBodyA: {
    color: '#2980b9',
    textDecoration: 'none',
  },
};

export default Privacy;
