import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const EnglishLanguageTraining = () => {
  return (
    <>
      <SEO title="English Language Training" />
      <Layout>
        {/* Breadcrumb Component */}
        <BreadcrumbOne 
          title="English Language Training" 
           rootUrl="/blog" 
  parentUrl="Blog"
          currentUrl="English Language Training"
        />

        <div className="blog-post-container">
          <div className="blog-post-header">
            <img
              src="https://img.freepik.com/free-psd/language-learning-landing-page-design-template_23-2149113650.jpg" // Use your actual image path
              alt="English Language Training Banner"
              className="blog-banner"
            />
            <h1 className="blog-title">English Language Training</h1>
            <p className="blog-author-date">By John Doe | January 5, 2025</p>
          </div>
          <div className="blog-content">
            <h2>Introduction</h2>
            <p>
              Mastering the English language is a crucial step in achieving success in today’s globalized world. Whether you're aiming to study, work, or settle abroad, proficiency in English is an essential skill. This blog will explore how our English language training program can help you achieve fluency and confidence.
            </p>

            <h2>Why English Language Training Matters</h2>
            <p>
              English is the most widely spoken language globally, used for international business, higher education, and cross-cultural communication. Our training programs are designed to equip you with the language skills you need to thrive in an English-speaking environment.
            </p>

            <h2>Blended & Hybrid Learning Approach</h2>
            <p>
              Our training structure combines both online and offline learning to offer you flexibility while ensuring you receive personalized instruction. Our expert instructors provide support and feedback through live sessions, making learning interactive and engaging.
            </p>

            <h2>Preparing for International Exams</h2>
            <p>
              If you're looking to take an international exam like IELTS, TOEFL, or the SELT exam, our training programs will help you prepare thoroughly. We offer tailored lessons focusing on reading, writing, listening, and speaking skills.
            </p>

            <h2>Conclusion</h2>
            <p>
              Investing in English language training is one of the best ways to unlock new opportunities. Whether you're preparing for academic, professional, or personal growth, our expert training services will help you reach your language goals.
            </p>
          </div>
        </div>
      </Layout>

      <style jsx>{`
        .blog-post-container {
          padding: 20px;
          max-width: 1000px;
          margin: auto;
        }

        .blog-post-header {
          text-align: center;
          margin-bottom: 40px;
        }

        .blog-banner {
          width: 100%;
          height: 400px;
          object-fit: cover;
          background-color: #f0f0f0;
        }

        .blog-title {
          font-size: 2.5rem;
          margin-top: 20px;
        }

        .blog-author-date {
          font-size: 1.2rem;
          color: #777;
          margin-top: 10px;
        }

        .blog-content {
          margin-top: 20px;
          font-size: 1.1rem;
          line-height: 1.6;
        }

        .blog-content h2 {
          font-size: 1.8rem;
          margin-top: 30px;
        }

        .blog-content ul {
          margin-top: 10px;
        }

        .blog-content ul li {
          margin-bottom: 8px;
        }
      `}</style>
    </>
  );
};

export default EnglishLanguageTraining;
