import React from 'react';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import Layout from '../../common/Layout';
import SEO from '../../common/SEO';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import { Navigate } from 'react-router-dom';

export const WorkPermit = () => {
    const workPermitBenefits = [
        {
            title: 'Legal Employment Opportunities',
            icon: 'icon-Briefcase',
            description: 'Work legally in your desired country with a valid work permit.'
        },
        {
            title: 'Pathway to Permanent Residency',
            icon: 'icon-Globe',
            description: 'Many countries offer PR options for work permit holders.'
        },
        {
            title: 'Higher Earning Potential',
            icon: 'icon-Money',
            description: 'Access better job opportunities with competitive salaries.'
        },
        {
            title: 'Global Networking',
            icon: 'icon-Network',
            description: 'Expand your professional connections internationally.'
        }
    ];

    const handleClick = () => {
        Navigate('/contact-us');
    };

    return (
        <>
            <SEO title="Work Permit Information" />
            <Layout>
                <BreadcrumbOne
                    title="Work Permit"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Work Permit"
                />

                <div className="container mt-5">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <img src="/images/about/work-permit.png" alt="Work Permit" className="img-fluid rounded shadow" />
                        </div>
                        <div className="col-lg-6">
                            <SectionTitle
                                classes="text-left"
                                slogan="Opportunities Abroad"
                                title="Why Apply for a Work Permit?"
                            />
                            <p className="mt-3">A work permit allows individuals to work legally in a foreign country, opening doors to new career opportunities. Many work permits come with benefits such as the possibility of permanent residency, higher salary potential, and global networking opportunities.</p>
                            <p>Each country has specific eligibility requirements, processing times, and work conditions. It is essential to research and choose the right work permit based on your career goals and destination preferences.</p>
                        </div>
                    </div>
                </div>

                <div className="container mt-5">
                    <div className="row">
                        {workPermitBenefits.map((benefit, index) => (
                            <div className="col-lg-3 col-md-6 text-center" key={index}>
                                <div className="benefit-box p-4 shadow rounded bg-white">
                                    <i className={benefit.icon + " icon-large text-primary"}></i>
                                    <h4 className="mt-3 font-weight-bold">{benefit.title}</h4>
                                    <p className="text-muted">{benefit.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="text-center mt-5">
                    <button
                        className="rn-btn edu-btn bg-primary text-white px-4 py-2 rounded"
                        type="button"
                        onClick={handleClick}>
                        <span>Contact Us for More Details</span>
                    </button>
                </div>
            </Layout>
        </>
    );
};
