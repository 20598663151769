import { useEffect, useState } from 'react';

const HeaderSticky = (offset = 0) => {
    const [sticky, setSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > offset);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [offset]);

    return sticky;
};

export default HeaderSticky;
