import React, { useState } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import PostData from '../../data/blog/PostData';

const BlogPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 16;

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = PostData.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(PostData.length / postsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <SEO title="Blog Page" description="Explore insightful articles on various topics." />
      <Layout>
        {/* Breadcrumb Component */}
        <BreadcrumbOne 
          title="Blog" 
          rootUrl="/" 
          parentUrl="Home" 
          currentUrl="Blog"
        />

        <div className="blog-page-container">
          <div className="blog-grid">
            {currentPosts.map((post, index) => (
              <div key={index} className="blog-card">
                <a href={post.postUrl || '#'}>
                  <img 
                    src={post.banner || '/path/to/placeholder-image.jpg'} 
                    alt={post.title}
                    className="blog-banner" 
                  />
                  <div className="blog-content">
                    <h2 className="blog-title">{post.title}</h2>
                    <p className="blog-description">{post.description}</p>
                    <div className="author-info">
                      <img 
                        src={post.authorImage || '/path/to/author-placeholder.jpg'} 
                        alt={post.authorName} 
                        className="author-icon" 
                      />
                      <span className="author-name">{post.authorName}</span>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </Layout>

      <style jsx>{`
        .blog-page-container {
          padding: 20px;
        }

        .blog-grid {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 20px;
        }

        .blog-card {
          border: 1px solid #ccc;
          border-radius: 8px;
          overflow: hidden;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease;
        }

        .blog-card:hover {
          transform: translateY(-5px);
        }

        .blog-banner {
          width: 100%;
          height: 200px;
          object-fit: cover;
          background-color: #f0f0f0;
        }

        .blog-content {
          padding: 15px;
        }

        .blog-title {
          font-size: 18px;
          font-weight: bold;
          margin: 0 0 10px;
        }

        .blog-description {
          font-size: 14px;
          color: #555;
          margin-bottom: 10px;
        }

        .author-info {
          display: flex;
          align-items: center;
        }

        .author-icon {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 10px;
        }

        .author-name {
          font-size: 14px;
          color: #333;
        }

        .pagination {
          display: flex;
          justify-content: center;
          margin-top: 20px;
        }

        .page-button {
          margin: 0 5px;
          padding: 10px 15px;
          border: none;
          border-radius: 4px;
          background-color: #007bff;
          color: #fff;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }

        .page-button.active {
          background-color: #0056b3;
        }

        .page-button:hover {
          background-color: #0056b3;
        }
      `}</style>
    </>
  );
};

export default BlogPage;
