import React from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import BreadcrumbOne from '../common/breadcrumb/BreadcrumbOne';

const Refund = () => {
  return (
    <Layout>
      <SEO title="Refund Policy" />
      <BreadcrumbOne 
        title="Refund Policy"
        paragraph="Last updated: February 03, 2025"
        breadcrumbPath={[
          { name: "Home", path: "/" },
          { name: "Refund Policy", path: "/refund-policy" }
        ]}
      />
      <div className="refund-policy-container" style={styles.termsContainer}>
  <section className="refund-policy-body" style={styles.termsBody}>
    <h2 style={styles.termsBodyH2}>Refund Policy</h2>
    <p style={styles.termsBodyP}><strong>Last Updated:</strong> February 3, 2025</p>

    <h3 style={styles.termsBodyH3}>Introduction</h3>
    <p style={styles.termsBodyP}>
      We strive to provide high-quality online English Learning. If you are not satisfied with your purchase, this Refund Policy explains the conditions and steps to request a refund.
    </p>

   { /*<h3 style={styles.termsBodyH3}>2. Eligibility for Refund</h3>
    <p style={styles.termsBodyP}>You may be eligible for a refund if:</p>
    <ul style={styles.termsBodyP}>
      <li>You request a refund within the specified refund period.</li>
      <li>You have not completed more than 20% of the course content.</li>
      <li>The course was purchased directly from our official website.</li>
    </ul>*/}

    <h3 style={styles.termsBodyH3}>Refund Process</h3>
    <p style={styles.termsBodyP}>
      If your refund request meets the eligibility criteria, we will process the refund to your original payment method. Refunds are typically credited within 5 business days.
    </p>

    <h3 style={styles.termsBodyH3}>Contact Us</h3>
    <p style={styles.termsBodyP}>
      If you have any questions about our refund policy, please contact us at 
      <a href="mailto:info@fetc.in" style={styles.termsBodyA}> info@fetc.in</a>.
    </p>
  </section>
</div>

    </Layout>
  );
};


const styles = {
    termsContainer: {
      fontFamily: 'Arial, sans-serif',
      margin: '20px auto',
      padding: '40px',
      maxWidth: '1000px',
      backgroundColor: '#fff',
      borderRadius: '12px',
      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
      boxSizing: 'border-box',
    },
    termsBody: {
      lineHeight: '1.6',
    },
    termsBodyH2: {
      fontSize: '24px',
      color: '#34495e',
      marginTop: '20px',
      fontWeight: 'bold',
    },
    termsBodyH3: {
      fontSize: '20px',
      color: '#34495e',
      marginTop: '15px',
    },
    termsBodyP: {
      fontSize: '16px',
      color: '#2c3e50',
      marginBottom: '16px',
    },
    termsBodyA: {
      color: '#2980b9',
      textDecoration: 'none',
    },
  };

export default Refund;
