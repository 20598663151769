import React, { useState } from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import BreadcrumbOne from '../common/breadcrumb/BreadcrumbOne';

const CheckoutPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    course: '',
    termsAccepted: false,
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    course: '',
    termsAccepted: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));

    // Clear the error when the input is changed
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    // Name validation: Should be non-empty and only letters
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required.';
    } else if (!/^[A-Za-z\s]+$/.test(formData.name)) {
      newErrors.name = 'Name must only contain letters and spaces.';
    }

    // Email validation: Should be a valid email format
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address.';
    }

    // Phone validation: Should be numbers only and length between 10 to 15
    if (!formData.phone.trim()) {
      newErrors.phone = 'Phone number is required.';
    } else if (!/^\d{10,15}$/.test(formData.phone)) {
      newErrors.phone = 'Phone number must be between 10 digits.';
    }

    // Course validation: Should be selected
    if (!formData.course) {
      newErrors.course = 'Please select a course.';
    }

    // Terms acceptance validation
    if (!formData.termsAccepted) {
      newErrors.termsAccepted = 'You must accept the terms and conditions.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Proceed with form submission logic
      console.log('Form submitted', formData);
    }
  };

  return (
    <Layout>
      <SEO title="Checkout" />
      {/* Adding BreadcrumbOne here */}
      <BreadcrumbOne 
        title="Checkout"
        rootUrl="/"
        parentUrl="Home"
        currentUrl="Checkout"
      />

      <div className="checkout-container">
        <form onSubmit={handleSubmit} className="checkout-form">
          <div className="left-bar">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="form-input"
              />
              {errors.name && <p className="error-message">{errors.name}</p>}
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="form-input"
              />
              {errors.email && <p className="error-message">{errors.email}</p>}
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                className="form-input"
              />
              {errors.phone && <p className="error-message">{errors.phone}</p>}
            </div>
          </div>

          <div className="right-bar">
            <div className="form-group">
              <label htmlFor="course">Select Course</label>
              <select
                id="course"
                name="course"
                value={formData.course}
                onChange={handleChange}
                required
                className="form-input"
              >
                <option value="25000">Syzygy ₹25,000</option>
                <option value="12000">Online Mock ₹12,000 </option>
                <option value="499">PEARSON VERSANT ₹499</option>
              </select>
              {errors.course && <p className="error-message">{errors.course}</p>}
            </div>

            <div className="payment-section">
              <p>Choose a payment method</p>
              {/* Payment gateway options can be added here */}
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  id="termsAccepted"
                  name="termsAccepted"
                  checked={formData.termsAccepted}
                  onChange={handleChange}
                />
                <label htmlFor="termsAccepted">I agree to the <a href="/terms"target="_blank" rel="noopener noreferrer">Terms & Conditions</a></label>
                {errors.termsAccepted && <p className="error-message">{errors.termsAccepted}</p>}
              </div>
            </div>

            <div className="form-group">
              <button type="submit" className="pay-now-btn">Pay Now</button>
            </div>
          </div>
        </form>
      </div>

      {/* CSS Styles */}
      <style jsx>{`
        .checkout-container {
          padding: 20px;
          max-width: 1200px;
          margin: 0 auto;
        }

        .checkout-form {
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }

        .left-bar, .right-bar {
          flex: 1;
          background-color: #f9f9f9;
          padding: 20px;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        .left-bar {
          margin-right: 10px;
        }

        h2 {
          text-align: center;
          margin-bottom: 20px;
          font-size: 2rem;
        }

        .form-group {
          margin-bottom: 20px;
        }

        .form-group label {
          font-weight: bold;
          display: block;
          margin-bottom: 5px;
        }

        .form-input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
          font-size: 1.6rem;
        }

        .form-input:focus {
          border-color: #4caf50;
          outline: none;
        }

        .payment-section {
          margin-top: 20px;
        }

        .pay-now-btn {
          padding: 12px 20px;
          border: none;
          background-color: #4caf50;
          color: white;
          font-size: 1.5rem;
          cursor: pointer;
          border-radius: 5px;
          width: 100%;
        }

        .pay-now-btn:hover {
          background-color: #45a049;
        }

        .form-group input[type="checkbox"] {
          margin-right: 8px;
        }

        .checkbox-container {
          margin-bottom: 5px;
        }

        .error-message {
          color: red;
          font-size: 1.2rem;
          margin-top: 5px;
        }
      `}</style>
    </Layout>
  );
};

export default CheckoutPage;
