import React from 'react';
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout';
import CustomCounter from '../../components/counterup/CustomCounter';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import Layout from '../../common/Layout';
import SEO from '../../common/SEO';
import { Navigate } from 'react-router-dom';

export const AboutCountryNewZealand = () => {
    const newZealandBenefits = [
        {
            title: 'World-Class Education System',
            icon: 'icon-Bag',
            number: 1000,
            suffix: '+'
        },
        {
            title: 'Affordable Tuition Fees',
            icon: 'icon-Money',
            number: 50,
            suffix: '%'
        },
        {
            title: 'Globally Recognized Degrees',
            icon: 'icon-Globe',
            number: 500,
            suffix: '+'
        },
        {
            title: 'Post-Study Work Opportunities',
            icon: 'icon-Briefcase',
            number: 300,
            suffix: '+'
        }
    ];

    const handleClick = () => {
        Navigate('/contact-us');
    };

    const newZealandItems = [
        {
            title: 'University of Auckland',
            numberOfCourses: '',
            icon: 'icon-Schoolbag',
            link: '#'
        },
        {
            title: 'University of Otago',
            numberOfCourses: '',
            icon: 'icon-student-read',
            link: '#'
        },
        {
            title: 'Victoria University of Wellington',
            numberOfCourses: '',
            icon: 'icon-Pencil',
            link: '#'
        },
        {
            title: 'University of Canterbury',
            numberOfCourses: '',
            icon: 'icon-Class',
            link: '#'
        },
        {
            title: 'Massey University',
            numberOfCourses: '',
            icon: 'icon-Setting',
            link: '#'
        },
        {
            title: 'Auckland University of Technology',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'Lincoln University',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        }
    ];

    const newZealandData = {
        title: 'New Zealand',
        mTitle: 'Why Study in New Zealand?',
        desc_1: "New Zealand offers a world-class education system, research-driven universities, and a high quality of life. With a focus on innovation and practical learning, New Zealand universities provide globally recognized degrees that enhance career opportunities worldwide.",
        desc_2: 'Apart from academics, New Zealand is known for its welcoming environment, stunning landscapes, and work-study opportunities. The country’s safe and multicultural atmosphere makes it an ideal destination for international students.',
        subTitle: 'Features of New Zealand',
        funFacts: newZealandBenefits,
        items: newZealandItems
    };

    return (
        <>
            <SEO title={`About ${newZealandData.title}`} />
            <Layout>
                <BreadcrumbOne
                    title="Study Abroad"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Study in New Zealand"
                />
                <CustomHomeAbout
                    sectionFormat={'1'}
                    title={newZealandData.title}
                    mTitle={newZealandData.mTitle}
                    desc_1={newZealandData.desc_1}
                    desc_2={newZealandData.desc_2}
                    subTitle={newZealandData.subTitle}
                    imgSrc={'/images/about/about-07/new-zealand.png'}
                />
                <CustomCounter
                    funFacts={newZealandData.funFacts}
                />
                <div className="container eduvibe-animated-shape">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                classes="text-center"
                                slogan=""
                                title="Universities in New Zealand"
                            />
                        </div>
                    </div>
                    <CategoryThreeCustom
                        items={newZealandData.items}
                    />
                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                    <button
                        className="rn-btn edu-btn mb--30"
                        type="button"
                        onClick={() => handleClick()}>
                        <span>Get in touch with us</span>
                    </button>
                </div>
            </Layout>
        </>
    );
};
