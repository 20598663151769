import React, { useState } from "react";

const Unit1CE17 = () => {
  const [text, setText] = useState("");
  const [feedback, setFeedback] = useState([]);
  const [criteriaFeedback, setCriteriaFeedback] = useState({});

  const wordLimit = 100;
  const wordCount = text.trim().split(/\s+/).filter(Boolean).length;

  const handleChange = (e) => {
    const words = e.target.value.trim().split(/\s+/).filter(Boolean);
    if (words.length <= wordLimit) {
      setText(e.target.value);
    } else {
      alert("You cannot write more than 100 words.");
    }
  };

  const handleSubmit = () => {
    const sentences = text.split(/[.!?]\s*/);
    const grammarErrors = checkGrammar(sentences);
    const criteriaFeedback = evaluateCriteria(sentences);
    setFeedback(grammarErrors);
    setCriteriaFeedback(criteriaFeedback);
  };

  const checkGrammar = (sentences) => {
    const grammarIssues = [];
    const verbIssues = /(?:\bis\b|\bam\b|\bare\b|\bwas\b|\bwere\b|\bhave\b|\bhas\b|\bhad\b|\bwill\b|\bwould\b)/gi;
    const articleIssues = /\b(a\s[aeiou]|an\s[^aeiou])/gi;

    sentences.forEach((sentence, index) => {
      const issues = [];

      if (!verbIssues.test(sentence)) {
        issues.push("Missing or incorrect verb usage");
      }
      if (articleIssues.test(sentence)) {
        issues.push("Incorrect article usage");
      }

      const pastWords = /\b(was|were|had)\b/gi;
      const presentWords = /\b(is|am|are|have|has)\b/gi;
      const futureWords = /\b(will|shall)\b/gi;

      const pastCount = (sentence.match(pastWords) || []).length;
      const presentCount = (sentence.match(presentWords) || []).length;
      const futureCount = (sentence.match(futureWords) || []).length;

      if ([pastCount, presentCount, futureCount].filter((count) => count > 0).length > 1) {
        issues.push("Inconsistent tense usage");
      }

      if (issues.length > 0) {
        grammarIssues.push({
          index,
          text: sentence.trim(),
          issues,
        });
      }
    });

    return grammarIssues;
  };

  const evaluateCriteria = (sentences) => {
    const feedback = {};

    if (sentences.length < 3) {
      feedback.TA = "Try to add a few more sentences to complete the task.";
    } else {
      feedback.TA = "Good completion of the task! Keep sentences simple and relevant.";
    }

    const connectors = /(\band\b|\bbut\b|\bbecause\b)/gi;
    if (!connectors.test(text)) {
      feedback.CC = "Add basic connectors like 'and,' 'but,' or 'because' for better flow.";
    } else {
      feedback.CC = "Good use of connectors! The sentences flow well.";
    }

    const complexWords = /\b(\w{10,})\b/g;
    if (text.match(complexWords)) {
      feedback.LR = "Use simpler vocabulary suited to everyday topics.";
    } else {
      feedback.LR = "Good choice of vocabulary for the topic.";
    }

    const spellingErrors = /[^\w\s.,!?]/g;
    if (text.match(spellingErrors)) {
      feedback.GRA = "Check spelling and punctuation for accuracy.";
    } else {
      feedback.GRA = "Good grammatical accuracy with simple structures.";
    }

    return Object.entries(feedback).find(([key, value]) => value.includes("Try") || value.includes("Check")) || ["General", "Good work!"];
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", textAlign: "center" }}>
      <h3 style={{ fontSize: "1.8rem", marginBottom: "20px" }}>Write about yourself</h3>
      <p style={{ fontSize: "1.8rem", marginBottom: "10px", color: "gray" }}>
        Please write a short paragraph about yourself (not more than 100 words).
      </p>
      <div style={{ position: "relative", width: "100%" }}>
        <textarea
          value={text}
          onChange={handleChange}
          rows="10"
          style={{
            width: "100%",
            padding: "10px",
            fontSize: "1.5rem",
            border: "2px solid #ccc",
            borderRadius: "5px",
            outline: "none",
          }}
        />
        <span
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            fontSize: "1.2rem",
            color: wordCount < wordLimit ? "red" : wordCount === wordLimit ? "green" : "red",
          }}
        >
          {wordCount}/{wordLimit} words
        </span>
      </div>
      <button
        onClick={handleSubmit}
        style={{
          marginTop: "30px",
          padding: "10px 20px",
          borderRadius: "8px",
          backgroundColor: "#28A745",
          color: "white",
          border: "none",
          cursor: "pointer",
          fontSize: "1.2rem",
        }}
      >
        Submit Answers
      </button>

      {feedback.length > 0 && (
        <div style={{ marginTop: "20px", textAlign: "left" }}>
          <h4 style={{ color: "#007BFF" }}>Grammar Feedback</h4>
          <ul>
            {feedback.map((error) => (
              <li
                key={error.index}
                style={{
                  backgroundColor: "#F8D7DA",
                  padding: "5px",
                  borderRadius: "5px",
                  marginBottom: "5px",
                }}
              >
                <strong>Sentence #{error.index + 1}:</strong> {error.text}
                <ul>
                  {error.issues.map((issue, i) => (
                    <li key={i} style={{ color: "darkred" }}>
                      {issue}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      )}

      {Object.keys(criteriaFeedback).length > 0 && (
        <div style={{ marginTop: "20px", textAlign: "left" }}>
          <h4 style={{ color: "#007BFF" }}>Criteria Feedback</h4>
          <ul>
            <li style={{ color: "#333", marginBottom: "5px" }}>
              <strong>{criteriaFeedback[0]}:</strong> {criteriaFeedback[1]}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Unit1CE17;
