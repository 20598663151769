import React from 'react';
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout';
import CustomCounter from '../../components/counterup/CustomCounter';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import Layout from '../../common/Layout';
import SEO from '../../common/SEO';
import { Navigate } from 'react-router-dom';

export const AboutCountryCanada = () => {
    const canadaBenefits = [
        {
            title: 'High-Quality Education System',
            icon: 'icon-Bag',
            number: 1000,
            suffix: '+'
        },
        {
            title: 'Affordable Tuition Fees',
            icon: 'icon-Money',
            number: 50,
            suffix: '%'
        },
        {
            title: 'Globally Recognized Degrees',
            icon: 'icon-Globe',
            number: 500,
            suffix: '+'
        },
        {
            title: 'Post-Study Work Opportunities',
            icon: 'icon-Briefcase',
            number: 300,
            suffix: '+'
        }
    ];

    const handleClick = () => {
        Navigate('/contact-us');
    };

    const canadaItems = [
        {
            title: 'University of Toronto',
            numberOfCourses: '',
            icon: 'icon-Schoolbag',
            link: '#'
        },
        {
            title: 'McGill University',
            numberOfCourses: '',
            icon: 'icon-student-read',
            link: '#'
        },
        {
            title: 'University of British Columbia',
            numberOfCourses: '',
            icon: 'icon-Pencil',
            link: '#'
        },
        {
            title: 'University of Alberta',
            numberOfCourses: '',
            icon: 'icon-Class',
            link: '#'
        },
        {
            title: 'University of Waterloo',
            numberOfCourses: '',
            icon: 'icon-Setting',
            link: '#'
        },
        {
            title: 'Western University',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'Simon Fraser University',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        }
    ];

    const canadaData = {
        title: 'Canada',
        mTitle: 'Why Study in Canada?',
        desc_1: "Canada is home to some of the world's best universities, offering high-quality education and diverse study options. With affordable tuition fees, excellent post-study work opportunities, and a multicultural environment, Canada is an attractive destination for students worldwide.",
        desc_2: 'Apart from academics, Canada provides a high quality of life, safe communities, and abundant career opportunities. Its welcoming policies for international students make it a top choice for higher education.',
        subTitle: 'Features of Canada',
        funFacts: canadaBenefits,
        items: canadaItems
    };

    return (
        <>
            <SEO title={`About ${canadaData.title}`} />
            <Layout>
                <BreadcrumbOne
                    title="Study Abroad"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Study in Canada"
                />
                <CustomHomeAbout
                    sectionFormat={'1'}
                    title={canadaData.title}
                    mTitle={canadaData.mTitle}
                    desc_1={canadaData.desc_1}
                    desc_2={canadaData.desc_2}
                    subTitle={canadaData.subTitle}
                    imgSrc={'/images/about/about-07/canada.png'}
                />
                <CustomCounter
                    funFacts={canadaData.funFacts}
                />
                <div className="container eduvibe-animated-shape">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                classes="text-center"
                                slogan=""
                                title="Universities in Canada"
                            />
                        </div>
                    </div>
                    <CategoryThreeCustom
                        items={canadaData.items}
                    />
                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                    <button
                        className="rn-btn edu-btn mb--30"
                        type="button"
                        onClick={() => handleClick()}>
                        <span>Get in touch with us</span>
                    </button>
                </div>
            </Layout>
        </>
    );
};