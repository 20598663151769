import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Nav from './Nav';

const ResponsiveMenu = ({ show, onClose, showSearch, onSearch }) => {
    useEffect(() => {
        const handleDropdowns = () => {
            const dropdowns = document.querySelectorAll('.popup-mobile-menu .has-dropdown > a');
            dropdowns.forEach((dropdown) => {
                dropdown.onclick = (e) => {
                    e.preventDefault();
                    const submenu = dropdown.nextElementSibling;
                    submenu?.classList.toggle('active');
                    dropdown.classList.toggle('open');
                };
            });
        };

        handleDropdowns();
    }, []);

    return (
        <>
            <div className={`popup-mobile-menu ${show ? 'active' : ''}`}>
                <div className="inner">
                    <div className="header-top">
                        <div className="logo">
                            <Link to="/">
                                <img src="/images/logo/FETC-logo.png" alt="Main Logo" />
                            </Link>
                        </div>
                        <div className="close-menu">
                            <button className="close-button" onClick={onClose}>
                                <i className="ri-close-line"></i>
                            </button>
                        </div>
                    </div>
                    <Nav />
                </div>
            </div>

            <div className={`edu-search-popup ${showSearch ? 'open' : ''}`}>
                <div className="close-button">
                    <button className="close-trigger" onClick={onSearch}>
                        <i className="ri-close-line"></i>
                    </button>
                </div>
                <div className="inner">
                    <form className="search-form" action="#">
                        <input
                            type="text"
                            className="eduvibe-search-popup-field"
                            placeholder="Search Here..."
                        />
                        <button className="submit-button">
                            <i className="icon-search-line"></i>
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ResponsiveMenu;
