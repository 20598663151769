import React, { useState } from 'react';
import ContactMeForm from '../../components/contact/ContactMeForm';

const FreeConsultation = () => {
    const [imageWidth, setImageWidth] = useState(); // Width of the image
    const [imageHeight, setImageHeight] = useState(); // Height of the image

    return (
        <>
            {/* <Layout> */}
            <div className="eduvibe-contact-me-top edu-contact-me-area edu-section-gap bg-color-white">
                <div className="container eduvibe-animated-shape">
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <div className="pr--75 pr_lg--0 pr_md--0 pr_sm--0">
                                <div 
                                    className="thumbnail" 
                                    style={{
                                        display: 'flex', 
                                        justifyContent: 'center', 
                                        alignItems: 'center', 
                                        height: '100%',
                                        textAlign: 'center'
                                    }}
                                >
                                    {/* Circular container with specific size */}
                                    <div style={{
                                        borderRadius: '50%', // Makes the image container circular
                                        width: '700px', // Image container width (circle size)
                                        height: '700px', // Image container height (circle size)
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        overflow: 'hidden', // Ensures the image fits inside the circle
                                        marginBottom: '20px' // Adds some space below the image container
                                    }}>
                                        <img
                                            className="w-100"
                                            src="/images/logo/fetclogo.png"
                                            alt="Contact Me"
                                            style={{
                                                objectFit: 'cover', // Ensures the image covers the area inside the circle
                                                width: `${imageWidth}px`, // Set the width dynamically based on state
                                                height: `${imageHeight}px`, // Set the height dynamically based on state
                                                filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.2))',
                                                transition: 'transform 0.3s ease', // Smooth zoom effect on hover
                                            }}
                                            onMouseEnter={(e) => e.target.style.transform = 'scale(1.1)'} // Zoom in on hover
                                            onMouseLeave={(e) => e.target.style.transform = 'scale(1)'} // Zoom out on hover
                                        />
                                    </div>
                                    <div style={{ marginTop: '10px', fontSize: '16px', color: '#333' }}>
                                        {/* Placeholder for image size or other text if necessary */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="section-title text-start mb--45">
                                <span className="pre-title">Contact Us</span>
                                <h3 className="title">Send Us A Message</h3>
                            </div>
                            <ContactMeForm formStyle="rnt-contact-form rwt-dynamic-form row" />
                        </div>
                    </div>

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="/images/shapes/shape-03-11.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-15-06.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-3">
                            <img src="/images/shapes/shape-09-03.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-4">
                            <img src="/images/shapes/shape-03-02.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>
            </div>
            {/* </Layout> */}
        </>
    );
}

export default FreeConsultation;
