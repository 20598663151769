import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const WorkStudySettleAbroad = () => {
  return (
    <>
      <SEO title="Work, Study & Settle Abroad" />
      <Layout>
        {/* Breadcrumb Integration */}
        <BreadcrumbOne 
          title="Work, Study & Settle Abroad" 
           rootUrl="/blog" 
  parentUrl="Blog"
          currentUrl="Work, Study & Settle Abroad"
        />

        <div className="blog-post-container">
          <div className="blog-post-header">
            <img
              src="https://img.freepik.com/premium-vector/flat-banner-getting-education-abroad-cartoon_81534-2509.jpg" // Use your actual image path
              alt="Work, Study & Settle Abroad Banner"
              className="blog-banner"
            />
            <h1 className="blog-title">Work, Study & Settle Abroad</h1>
            <p className="blog-author-date">By Jane Smith | December 20, 2024</p>
          </div>
          <div className="blog-content">
            <h2>Introduction</h2>
            <p>
              Aspiring to study, work, or settle abroad is a dream for many individuals seeking better career opportunities, personal growth, and a new cultural experience. In this post, we will walk you through the various steps to make this dream a reality, from understanding your options to navigating visa and immigration processes.
            </p>
            
            <h2>Study Abroad: Unlocking Global Opportunities</h2>
            <p>
              Studying abroad offers numerous benefits, including access to world-class education, exposure to international ideas, and the opportunity to network with professionals across the globe. Let’s explore how you can make this dream come true.
            </p>

            <h2>Work Abroad: Building an International Career</h2>
            <p>
              Working abroad gives you a chance to experience new cultures and gain international work experience. You’ll learn new skills, enhance your career prospects, and contribute to the global economy. We help you find the right job opportunities and manage the application process.
            </p>

            <h2>Settle Abroad: A Smooth Transition</h2>
            <p>
              Moving abroad to settle is a life-changing experience. Whether you're seeking permanent residency or planning to stay for a few years, we offer visa assistance, help you choose the right destination, and ensure that you have all the support you need to settle successfully.
            </p>

            <h2>Conclusion</h2>
            <p>
              Whether you are looking to study, work, or settle abroad, the key to success lies in proper planning and expert support. Our services are designed to make your transition seamless, so you can focus on what truly matters – your personal and professional growth.
            </p>
          </div>
        </div>
      </Layout>

      <style jsx>{`
        .blog-post-container {
          padding: 20px;
          max-width: 1000px;
          margin: auto;
        }

        .blog-post-header {
          text-align: center;
          margin-bottom: 40px;
        }

        .blog-banner {
          width: 100%;
          height: 400px;
          object-fit: cover;
          background-color: #f0f0f0;
        }

        .blog-title {
          font-size: 2.5rem;
          margin-top: 20px;
        }

        .blog-author-date {
          font-size: 1.2rem;
          color: #777;
          margin-top: 10px;
        }

        .blog-content {
          margin-top: 20px;
          font-size: 1.1rem;
          line-height: 1.6;
        }

        .blog-content h2 {
          font-size: 1.8rem;
          margin-top: 30px;
        }

        .blog-content ul {
          margin-top: 10px;
        }

        .blog-content ul li {
          margin-bottom: 8px;
        }
      `}</style>
    </>
  );
};

export default WorkStudySettleAbroad;
