import React from 'react';
import SEO from '../../common/SEO';
import HomeThreeService from '../../components/home-three/HomeThreeService';
import AboutSix from '../../components/about/AboutSix';
import FooterOne from '../../common/footer/FooterOne';
import FreeConsultation from '../innerpages/FreeConsultation';
import BannerFour from '../../components/banner/BannerFour';
import HomeFourAbout from '../../components/home-four/HomeFourAbout';
import HeaderOne from '../../common/header/HeaderOne';
import CoursePage from '../course/CoursePage';

const HomeThree = () => {
    return (
        <>
            <SEO title="FETC - Home" />
             
            <HeaderOne styles="header-transparent header-style-3" />

            <BannerFour/>
            
            <HomeThreeService />

            <CoursePage />

            <HomeFourAbout />
            
            <AboutSix />

            <FreeConsultation />

            <FooterOne />
        </>
    )
}

export default HomeThree;